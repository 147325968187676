import React from 'react'
import SectionDoubleVideo from 'src/components/SectionDoubleVideo'

const backgroundImageMobile = 'https://central-imagens.bancointer.com.br/images-without-small-versions/webp-background-super-app-financeiro-mobile/image.webp'
const backgroundImageMD = 'https://central-imagens.bancointer.com.br/images-without-small-versions/webp-background-super-app-financeiro-md/image.webp'
const backgroundImageLG = 'https://central-imagens.bancointer.com.br/images-without-small-versions/webp-background-super-app-financeiro-lg/image.webp'
const backgroundImageXL = 'https://central-imagens.bancointer.com.br/images-without-small-versions/webp-background-super-app-financeiro-xl/image.webp'
const backgroundImageXXL = 'https://central-imagens.bancointer.com.br/images-without-small-versions/webp-background-super-app-financeiro-xxl/image.webp'

const Header = () => {
  return (
    <SectionDoubleVideo
      urlMp4='https://static.bancointer.com.br/videos/superapp/webm/inter-aspirador-super-app.webm'
      urlWeb='https://static.bancointer.com.br/videos/superapp/webm/inter-aspirador-super-app.webm'
      titleSection=''
      linkPlayVideo='https://www.youtube.com/embed/UO648M7VTcI?si=dyzOjsgdoArr7Xhs?&autoplay=1'
      dataLayerProps={{
        sectionName: 'Você não sabia que precisava, até ser Inter.',
      }}
      imagesBg={{
        sm: backgroundImageMobile,
        md: backgroundImageMD,
        lg: backgroundImageLG,
        xxl: backgroundImageXL,
        xxxl: backgroundImageXXL,
      }}
    />
  )
}

export default Header
