import styled from 'styled-components'
import { orange } from 'src/styles/newColors'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  h1 {
    font-size: 40px;

    @media ${device.desktopLG} {
      font-size: 56px;
    }

    @media ${device.desktopXL} {
      font-size: 80px;
    }

    @media ${device.desktopXXXL} {
      font-size: 104px;
    }
  }

  align-items: center;
  display: flex;
  min-height: 780px;
  background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/app-financeiro-360/image.webp');
  background-repeat: no-repeat;
  background-size: cover;

  @media ${device.tablet} {
    min-height: 409px;
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/app-financeiro-web/image.webp');
    background-size: cover;
  }

  @media ${device.desktopLG} {
    min-height: 546px;
  }

  @media ${device.desktopXL} {
    min-height: 768px;
    background-position: top center;
  }

  @media ${device.desktopXXXL} {
    min-height: 100vh;
  }

  button {
    background: ${orange.extra};
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0 10px;
    border-radius: 8px;
    height: 48px;

    @media ${device.tablet} {
      max-width: 328px;
    }
    @media ${device.desktopLG} {
      max-width: 230px;
    }
    @media ${device.desktopXL} {
      max-width: 323px;
    }
    @media ${device.desktopXXXL} {
      max-width: 430px;
    }
  }
`

export const CTA = styled.div`
  background: none;
  border: none;
  padding: 0;
  color: #1C1C1C;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;

  &:focus {
    border: 2px solid;
  }
  &:hover {
    opacity: 0.7;
  }
`
