import React, { useState } from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import SectionComponent from 'src/components/SectionComponent'
import ImageWebp from 'src/components/ImageWebp'

import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD, device } from 'src/styles/breakpoints'

import styled from 'styled-components'
import { ModalPropsSuperApp } from '../../types'

const FacilRapidoGratuito = ({ setIsModal, setDataLayer }: ModalPropsSuperApp) => {
  const width = useWidth()

  const [ sendDatalayerEvent ] = useDataLayer()
  const urlMobileImage = 'https://central-imagens.bancointer.com.br/images-without-small-versions/superapp-mockup-1024/image.webp'
  const urlDesktopImage = 'https://central-imagens.bancointer.com.br/images-without-small-versions/superapp-mockup-1920/image.webp'

  const [ dataLayer ] = useState<IDataLayerParams>({
    section: 'dobra_06',
    section_name: 'Fácil, rápido e gratuito.',
    element_action: 'click button',
    element_name: 'Abra sua conta',
  })

  const Button = styled.section`
    @media ${device.tablet} {
      max-width: 246px;
    }
    @media ${device.desktopLG} {
      max-width: 368px;
    }
    @media ${device.desktopXL} {
      max-width: 454px;
    }
  `

  return (
    <SectionComponent
      id='o-primeiro-super-app'
      SectionStyles='bg-white align-items-center'
      minHeight={{ sm: '700px', md: '515px', lg: '685px', xl: '788px', xxl: '1080px' }}
    >
      <div className='col-12 col-md-6 text-center text-md-left'>
        <h2 className='font-citrina text-new-orange-dark fs-40 lh-44 fs-md-48 lh-md-52 fs-lg-56 lh-lg-61 fs-xxl-80 lh-xxl-90 mb-4'>
          <span className='d-block'>Fácil, rápido</span> e gratuito.
        </h2>
        <p className='text-graphite fs-14 lh-16 fs-md-25 lh-md-28 px-5 px-md-0'><span className='d-block'>Cartão de crédito e</span> conta digital pra você.</p>
        <Button
          title=' Abra sua conta'
          className='btn btn--lg text-white bg-primary--500 mt-3 mb-5 mb-md-0 text-none'
          onClick={() => {
            setIsModal && setIsModal(true)
            setDataLayer(dataLayer)
            sendDatalayerEvent(dataLayer)
          }}
        >
          Abra sua conta
        </Button>
      </div>
      <div className='col-12 col-md-6 d-flex justify-content-center mt-2 mt-md-0'>
        <ImageWebp
          arrayNumbers={[ 280, 290, 470, 520, 780 ]}
          pathSrc={width <= WIDTH_MD ? urlMobileImage : urlDesktopImage}
          altDescription='Composição de imagens com a tela de um telefone celular mostrando o Super App Inter e um cartão de crédito Inter'
        />
      </div>
    </SectionComponent>
  )
}

export default FacilRapidoGratuito
