import React, { useState } from 'react'
import Img from 'gatsby-image'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import SectionComponent from 'src/components/SectionComponent'
import OpenVideo from 'src/components/OpenVideo'

import { ModalPropsSuperApp } from '../../types'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/styles/breakpoints'

import usePageQuery from '../../pageQuery'

const DaPraFalarDeDinheiro = ({ setDataLayer }: ModalPropsSuperApp) => {
  const width = useWidth()
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()
  const urlVideo = 'https://www.youtube.com/embed/Dazp-Qhaj6A?si=_-h3R8nsjqww7UPG?&autoplay=1'

  const [ dataLayer ] = useState<IDataLayerParams>({
    section: 'dobra_03',
    section_name: 'Dá pra falar de dinheiro com leveza? Sim e podemos provar',
    element_action: 'click video',
    element_name: 'Assista ao vídeo',
    redirect_url: urlVideo,
  })

  return (
    <SectionComponent
      id='da-pra-falar-de-dinheiro'
      SectionStyles='bg-white align-items-center'
      RowStyles='justify-content-md-center'
      ContainerStyles='py-md-3'
      minHeight={{ sm: '560px', md: '720px', lg: '502px', xl: '648px', xxl: '800px' }}
    >
      <div className='col-11 col-md-6 col-lg-5 text-center text-lg-left pr-lg-5 mb-md-5 mb-lg-0'>
        <h2 className='font-citrina text-primary--500 fs-28 lh-34 fs-md-40 lh-md-44 fs-xl-56 lh-xl-62 fs-xxl-72 lh-xxl-80 mb-4'>
          <span className='d-block d-md-inline'>Dá pra falar de</span> dinheiro com leveza? Sim e podemos provar.
        </h2>
        <p className='text-graphite fw-600 fs-14 lh-16 fs-md-22 lh-md-24 fs-xl-25 lh-xl-28 px-5 px-md-0 mb-4 mb-md-0'>
          Confira o nosso game show comandado por Tatá Werneck.
        </p>
      </div>
      <div className='col-12 col-lg-6 col-xl-7 mt-2 mt-md-0 mt-md-4 mt-lg-0'>
        <OpenVideo
          link={urlVideo}
          styles='d-flex justify-content-md-center'
          onClick={() => {
            setDataLayer(dataLayer)
            sendDatalayerEvent(dataLayer)
          }}
        >
          <Img
            className='mt-4 mt-md-0 cursor-pointer'
            fluid={ width <= WIDTH_MD ? data.daPraFalarDeDinheiroMobile.fluid : data.daPraFalarDeDinheiroDesktop.fluid}
            alt='Imagem com Tatá Werneck do primeiro episódio Game Show Inter'
            title='Assistir vídeo'
          />
        </OpenVideo>
      </div>
    </SectionComponent>
  )
}

export default DaPraFalarDeDinheiro
