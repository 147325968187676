import React from 'react'
import OpenAccountSectionForm from 'src/components/UI/Forms/OpenAccountSectionForm'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_XXL } from 'src/styles/breakpoints'

import logo from 'src/assets/images/nova-logo-inter.svg'

import SectionComponent from 'src/components/SectionComponent'

type DataProps = {
  qrBaixeApp?: HTMLImageElement;
  agoraBaixeOAppLink: string;
}

const PrimeiroSuperApp = ({ qrBaixeApp, agoraBaixeOAppLink }: DataProps) => {
  const width = useWidth()

  return (
    <SectionComponent
      id='o-primeiro-super-app'
      SectionStyles='bg-yellow--extra-light'
      RowStyles='justify-content-start justify-content-md-between'
      minHeight={{ sm: 'auto', md: '482px', lg: '706px', xl: '100vh', xxl: '100vh' }}
    >
      <div className='col-12 col-md-6'>
        <div className='col-12 col-xl-10 px-0'>
          <h1 className='font-citrina text-grayscale--600 fs-40 lh-44 fs-md-40 lh-md-44 fs-lg-56 lh-lg-61 fs-xxl-80 lh-xxl-90 mb-0'>
            <img
              src={logo} alt='Logo Inter'
              width={width < WIDTH_XXL ? 180 : 230}
              height={width < WIDTH_XXL ? 40.5 : 58.22}
              className='d-block mb-2 ml-n2'
            />
            O primeiro Super App da sua vida financeira.
          </h1>
        </div>
      </div>
      <div className='col-12 col-md-6 col-lg-5 mt-5 mb-4 my-md-0'>
        <OpenAccountSectionForm
          section='dobra_02'
          sectionName='Inter. O primeiro Super App da sua vida financeira.'
          customOrigin='lp-campanhabranding'
          qrBaixeApp={qrBaixeApp}
          agoraBaixeOAppLink={agoraBaixeOAppLink}
        />
      </div>
    </SectionComponent>
  )
}

export default PrimeiroSuperApp
