import styled from 'styled-components'
import { grayscale } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const Accordeon = styled.div`
  max-height: 100px;
  padding: 15px;
  transition: max-height 0.25s;
  overflow: hidden;
  border: 2px solid ${grayscale[200]};
  border-radius: 8px;
  margin-bottom: 20px;
  align-items: center;
  display: flex;
  flex-direction: column;

  @media ${device.desktopXL} {
    max-height: 69px;
  }

  &.active {
    max-height: 1000px;
    transition: max-height 0.25s ease-in;
    overflow: visible;
  }
`

export const AccordeonTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    cursor: pointer;
    width: 32px;
    height: 32px;

    &.arrow-active {
      transform: rotate(180deg);
      transition: 0.5s;
    }
  }
`

export const AccordeonContent = styled.div`
  max-width: 600px;
`

export const InfoList = styled.ul`
  width: 100%;
  margin-bottom: 0;

  li {
    margin-left: 17px;
    margin-right: 5px;

    @media ${device.desktopXL} {
      margin-left: 10px;
    }
  }
`
