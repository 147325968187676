import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { primary } from 'src/styles/newColors'

const bgVidaFinanceiraMobile = 'https://central-imagens.bancointer.com.br/images-without-small-versions/super-app-dobra2-mobile/image.webp'
const bgVidaFinanceiraTabletDesktop = 'https://central-imagens.bancointer.com.br/images-without-small-versions/super-app-dobra2/image.webp'

export const SectionVidaFinanceira = styled.section`
  background: ${primary[500]};
  min-height: 780px;
  padding: 40px 0 56px 0;
  background-image: url(${bgVidaFinanceiraMobile});
  background-repeat: no-repeat;
  background-size: cover;

  @media ${device.tablet} {
    background-image: url(${bgVidaFinanceiraTabletDesktop});
    min-height: 591px;
    background-position: center right -140px;
    padding-bottom: 40px;
  }
  @media ${device.desktopLG} {
    min-height: 591px;
    background-position: center center;
  }
  @media ${device.desktopXL} {
    min-height: 810px;
  }
  @media ${device.desktopXXXL} {
    min-height: 1080px;
  }
`
