import React, { useState } from 'react'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/styles/breakpoints'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Section, CTA } from './style'
import { ModalPropsSuperApp } from '../../types'
import ArrowRight from '@interco/icons/build-v4/orangeds/MD/arrow-right'

type DataProps = {
  qrBaixeApp?: HTMLImageElement;
  agoraBaixeOAppLink: string;
}

const Hero = ({ setIsModal, setDataLayer }: ModalPropsSuperApp) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const windowWidth = useWidth(300)
  const [ dataLayer ] = useState<IDataLayerParams>({
    section: 'dobra_01',
    section_name: 'Você não sabia que precisava, até ser Inter.',
    element_action: 'click button',
    element_name: 'Abra sua conta',
  })

  return (
    <Section className='py-4 d-flex align-items-end align-items-md-center' id='o-primeiro-super-app'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 px-0'>
            <h1 className={`font-citrina ${windowWidth < WIDTH_MD ? ' text-white' : ' text-grayscale--500'} lh-44 lh-lg-61 lh-xl-88 mb-0`}>
              <span className='d-md-block'>Você não sabia</span> <span className='d-md-block'>que precisava,</span> até ser Inter.
            </h1>
            <CTA
              className={`${windowWidth < WIDTH_MD ? ' text-white' : ' text-grayscale--500'} fs-16 fs-xl-24 mt-3 text-none cursor-pointer`}
              onClick={() => {
                setIsModal && setIsModal(true)
                setDataLayer(dataLayer)
                sendDatalayerEvent(dataLayer)
              }}
            >
              Abra sua conta
              <ArrowRight height={24} width={24} color={`${windowWidth < WIDTH_MD ? ' #FFFFFF' : ' #161616'}`} className='ml-2' />
            </CTA>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Hero
