import React, { useState, useEffect, MouseEvent, ChangeEvent } from 'react'
import axios from 'axios'
import { differenceInYears } from 'date-fns'
import { useForm, UseFormMethods } from 'react-hook-form'
import { translate, InjectedTranslateProps } from 'react-i18next'
import { sendDataLayerEvent, getParameterByName, sendCDPFormData } from 'src/shared/helpers'
import AcceptTerms from 'src/components/AcceptTerms/OpenAccount'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import * as Masks from 'inter-frontend-lib-util-form/lib/masks'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import * as URLS from 'src/config/api/Urls'
import { Link } from 'gatsby'

import { WIDTH_MD } from 'src/utils/breakpoints'
import useWidth from 'src/hooks/window/useWidth'

import errorImg from 'src/assets/images/shared/erro-pig.png'
import BaixeOApp from 'src/assets/images/qrcode-header.jpg'
import sucesso from '../images/sucesso.png'

import { Container } from './style'

type OpenAccountSectionFormProps = {
  customOrigin?: string;
  qrBaixeApp?: HTMLImageElement;
  agoraBaixeOAppLink?: string;
  formName?: string;
  deepLink?: string;
  downloadAppCustomMessage?: string;
  section: string;
  sectionName: string;
}
interface IFormValues {
  nome: string;
  email: string;
  celular: string;
  cpf: string;
  dataNascimento: string;
}

function OpenAccountSectionForm ({
  customOrigin,
  t,
  qrBaixeApp,
  formName,
  deepLink,
  agoraBaixeOAppLink,
  downloadAppCustomMessage,
  section,
  sectionName,
}: OpenAccountSectionFormProps & InjectedTranslateProps) {
  const { register, errors, handleSubmit, setValue }: UseFormMethods<IFormValues> = useForm<IFormValues>()
  const windowWidth = useWidth(200)
  const [ loading, setLoading ] = useState(false)
  const [ sent, setSent ] = useState(false)
  const [ underAge, setUnderAge ] = useState(false)
  const [ error, setError ] = useState(false)
  const [ filled, setFilled ] = useState(false)
  const [ accept, setAccept ] = useState(false)
  const [ utmSource, setUtmSource ] = useState<string | string[] | null | undefined>(null)
  const [ utmMedium, setUtmMedium ] = useState<string | string[] | null | undefined>(null)
  const [ utmCampaign, setUtmCampaign ] = useState<string | string[] | null | undefined>(null)
  const [ utmContent, setUtmContent ] = useState<string | string[] | null | undefined>(null)

  const [ dataLayer ] = useState<IDataLayerParams>({
    section: section,
    section_name: sectionName,
    element_action: 'click button',
    element_name: t('privacyPolicy'),
    redirect_url: t('privacyLink'),
  })

  const [ sendDatalayerEvent ] = useDataLayer()

  const urlMobile = 'https://intergo.app/7820e737'
  const urlDesktop = '/pra-voce/conta-digital/conta-kids/'
  const urlUnderAge = windowWidth < WIDTH_MD ? urlMobile : urlDesktop

  useEffect(() => {
    setUtmSource(getParameterByName('utm_source', ''))
    setUtmMedium(getParameterByName('utm_medium', ''))
    setUtmCampaign(getParameterByName('utm_campaign', ''))
    setUtmContent(getParameterByName('utm_content', ''))
  }, [ ])

  const sendForm = async (data: IFormValues) => {
    setLoading(true)
    const newDate = data.dataNascimento.replace(/\D/g, ',').split(',').reverse().join(',')
    const result = differenceInYears(new Date(), new Date(newDate))

    const formData = {
      ...data,
      cpf: data.cpf.replace(/\D/g, ''),
      celular: data.celular.replace(/\D/g, ''),
      dataNascimento: data.dataNascimento.replace(/\D/g, ',').split(',').reverse().join('-'),
      termoAceito: accept,
      origin: customOrigin || 'site-institucional',
      utmCampaign: utmCampaign?.toString(),
      utmMedium: utmMedium?.toString(),
      utmSource: utmSource?.toString(),
      utmContent: utmContent?.toString(),
    }

    try {
      if (result < 18) {
        setUnderAge(true)
        sendDatalayerEvent({
          section: section,
          section_name: sectionName,
          element_name: t('continue'),
          element_action: 'click button',
          element_previous: t('isHaveAnAccount'),
      })
      } else {
        await axios.put(`${URLS.OPEN_ACCOUNT_V2}`, formData)
        setLoading(false)
        setSent(true)
        sendDataLayerEvent('form_submitted')
        sendCDPFormData({ formName: formName, cpf: formData.cpf, email: formData.email })
        sendDatalayerEvent({
          section: section,
          section_name: sectionName,
          element_name: t('continue'),
          element_action: 'submit',
          element_previous: t('isHaveAnAccount'),
          step: 'success',
        })
      }
    } catch (e) {
      setError(true)
      setLoading(false)
      setFilled(false)
      sendDatalayerEvent({
        section: section,
        section_name: sectionName,
        element_name: t('continue'),
        element_action: 'click button',
        element_previous: t('isHaveAnAccount'),
      })
    }
  }

  const handleReturn = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setError(false)
    setSent(false)
    setLoading(false)
    setFilled(false)
  }

  const asFilled = (evt: MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    if (windowWidth < WIDTH_MD) {
      window.location.href = agoraBaixeOAppLink ? agoraBaixeOAppLink : 'https://intergo.app/7820e737'
    } else {
      setSent(false)
      setFilled(true)
    }
  }

  const asUnderAge = (evt: MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    window.location.href = urlUnderAge
  }

  return (
    <>
      { error && (
        <Container className='d-flex align-items-center'>
          <div className='col-12 text-center'>
            <h3 className='font-sora fs-20 lh-24 mb-5'>{t('errorTitle')}</h3>
            <img src={errorImg} alt='Imagem de erro' className='mr-4' width={185} />
            <p className='text-grayscale--400 fs-18 lh-22 pt-3'>
              {t('errorMessage')} <br />
              <button
                title={t('errorLinkMessage') + t('errorFinalMessage')}
                className='bg-transparent border-0 fw-600'
                onClick={(e: MouseEvent<HTMLButtonElement>) => {
                  handleReturn(e)
                  sendDatalayerEvent({
                    section: section,
                    section_name: sectionName,
                    element_name: t('errorLinkMessage'),
                    element_action: 'click button',
                    element_previous: t('isHaveAnAccount'),
                  })
                }}
              >
                {t('errorLinkMessage')}
              </button>
              {t('errorFinalMessage')}
            </p>
          </div>
        </Container>
      )}
      { sent && (
        <Container className='d-flex align-items-center sent'>
          <div className='col-12 text-center'>
            <p className='fs-14 lh-17 fs-md-16 lh-md-20 text-grayscale--400 fw-600'>
              {t('receivedYourData')}
            </p>
            <h3 className='font-sora fs-24 lh-28 text-grayscale--500 mb-4 fw-600'>
              <span className='d-block'>{t('now')} <span className='text-orange--extra'>{t('downloadOurApp')}</span></span>
              {t('andOpenYourAccount')}
            </h3>
            <div className='col-12 pb-2 mx-auto d-none d-md-block 1'>
              <img src={qrBaixeApp || BaixeOApp} alt='Baixe o App Inter' width={160} />
            </div>
            <div className='col-12 d-none d-md-block'>
              <hr />
              <p className='fs-14 lh-17 text-left mb-0' dangerouslySetInnerHTML={{ __html: t('sentMessage') }} />
            </div>
          </div>
        </Container>
      )}
      { underAge && (
        <Container className='d-flex align-items-center under-age'>
          <div className='col-12 text-md-center text-lg-left'>
            <h3 className='font-sora fs-16 lh-20 text-grayscale--400 fw-400 fw-md-600'>
              {t('underAge')}
            </h3>
            <p className='fs-20 lh-23 fs-md-24 lh-md-28 text-grayscale--500 mb-4 fw-600'>
              <span className='d-block'>
                {t('knowOur')}
                <span
                  className='text-orange--extra cursor-pointer'
                  onClick={(evt: MouseEvent<HTMLButtonElement>) => {
                    asUnderAge(evt)
                    sendDatalayerEvent({
                      section: section,
                      section_name: sectionName,
                      element_name: t('kidsAccount'),
                      element_action: 'click button',
                      element_previous: t('isHaveAnAccount'),
                      redirect_url: urlUnderAge,
                    })
                  }}
                > {t('kidsAccount')}
                </span>,
              </span>
              {t('alsoCompleteDigitalFree')}
            </p>
            <div className='col-12 pb-2 mx-auto d-lg-none text-center'>
              <img src={sucesso} alt='Imagem de sucesso' style={{ maxWidth: '204px' }} />
            </div>
            <div className='col-12 pb-2 mx-auto d-none d-lg-block text-center'>
              <img src={BaixeOApp} alt='Baixe o App Inter' style={{ maxWidth: '204px' }} />
            </div>
            <div className='col-12 d-none d-lg-block px-0'>
              <hr />
              <p className='fs-14 lh-17 text-left'>
                {t('sentMessage')}
              </p>
              <p className='fs-14 lh-17 text-left mb-0'>
                {t('underPrivacy')}
              </p>
            </div>
          </div>
        </Container>
      )}
      {filled && (
        <Container className='d-flex align-items-center filled'>
          <div className='col-12 text-center'>
            <h3
              className='font-sora fs-20 lh-24 fw-600 text-grayscale--500 mt-3'
              dangerouslySetInnerHTML={{ __html: t('nowDownload') }}
            />
            <p className='fs-16 lh-20 text-grayscale--400 mb-4' dangerouslySetInnerHTML={{ __html: t('pointPhone') }} />
            <div className='col-12'>
              <img src={qrBaixeApp || BaixeOApp} alt='Baixe o App Inter' width={185} height={185} />
            </div>
          </div>
        </Container>
      )}
      <Container className={`${error || sent || filled || underAge ? 'd-none' : 'd-flex'} align-items-center`}>
        <div className='row'>
          <div className='col-12 text-center pb-4 pt-lg-0'>
            <h2 className='fs-20 lh-24 text-left text-md-center mb-2 text-grayscale--500'>
              {t('openYourAccount')}
            </h2>
          </div>
          <div className='col-12'>
            <form className='form--default' name='open_account' onSubmit={handleSubmit(sendForm)}>
              <div className='body-form'>
                <div className='col-12 d-flex flex-column px-0 px-md-3 mb-3 pb-1'>
                  <label htmlFor='nome' className='fs-12 lh-15 fw-600'>{t('inputName.label')}</label>
                  <input
                    ref={register({
                      required: t('inputName.error'),
                      validate: {
                        isName: (value: string) => Validations.name(value) || t('inputName.error'),
                      },
                    })}
                    name='nome'
                    id='nome'
                    type='text'
                    maxLength={100}
                    placeholder={t('inputName.placeholder')}
                  />
                  {errors.nome && <p className='fs-12 text-red--base mb-0 text-right'>{errors.nome.message}</p>}
                </div>
                <div className='col-12 d-flex flex-column px-0 px-md-3 mb-3 pb-1'>
                  <label htmlFor='celular' className='fs-12 lh-15 fw-600'>{t('inputPhone.label')}</label>
                  <input
                    ref={register({
                      required: t('inputPhone.error'),
                      pattern: {
                        value: /^\(\d{2}\) \d{4,5}-\d{4}$/,
                        message: t('inputPhone.error'),
                      },
                    })}
                    name='celular'
                    id='celular'
                    type='tel'
                    placeholder={t('inputPhone.placeholder')}
                    maxLength={15}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('celular', Masks.MaskPHONE(event.currentTarget.value))}
                  />
                  {errors.celular && <p className='fs-12 text-red--base mb-0 text-right'>{errors.celular.message}</p>}
                </div>
                <div className='col-12 d-flex flex-column px-0 px-md-3 mb-3 pb-1'>
                  <label htmlFor='email' className='fs-12 lh-15 fw-600'>{t('inputEmail.label')}</label>
                  <input
                    ref={register({
                      required: t('inputEmail.error'),
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: t('inputEmail.error'),
                      },
                    })}
                    name='email'
                    id='email'
                    type='text'
                    placeholder={t('inputEmail.placeholder')}
                  />
                  {errors.email && <p className='fs-12 text-red--base mb-0 text-right'>{errors.email.message}</p>}
                </div>
                <div className='col-12 d-flex flex-column px-0 px-md-3 mb-3 pb-1'>
                  <label htmlFor='cpf' className='fs-12 lh-15 fw-600'>{t('inputCPF.label')}</label>
                  <input
                    ref={register({
                      required: t('inputCPF.error'),
                      validate: {
                        isCpf: (value: string) => Validations.cpf(value) || t('inputCPF.error'),
                      },
                    })}
                    name='cpf'
                    id='cpf'
                    type='tel'
                    placeholder={t('inputCPF.placeholder')}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('cpf', Masks.MaskCPF(event.currentTarget.value))}
                  />
                  {errors.cpf && <p className='fs-12 text-red--base mb-0 text-right'>{errors.cpf.message}</p>}
                </div>
                <div className='col-12 d-flex flex-column px-0 px-md-3 pb-1'>
                  <label htmlFor='dataNascimento' className='fs-12 lh-15 fw-600'>{t('inputBirthDate.label')}</label>
                  <input
                    ref={register({
                      required: t('inputBirthDate.error'),
                      validate: {
                        isDate: (value: string) => Validations.dateValid(value) || t('inputBirthDate.error'),
                      },
                    })}
                    name='dataNascimento'
                    id='dataNascimento'
                    type='tel'
                    placeholder={t('inputBirthDate.placeholder')}
                    maxLength={10}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('dataNascimento', Masks.MaskDATE(event.currentTarget.value))}
                  />
                  {errors.dataNascimento && <p className='fs-12 text-red--base mb-0 text-right'>{errors.dataNascimento.message}</p>}
                </div>
                <div className='col-12 d-flex flex-column px-0 px-md-3'>
                  <AcceptTerms
                    accept={accept}
                    dataLayer={dataLayer}
                    setAccept={setAccept}
                    name='form-conta-digital-pf'
                  />
                </div>
                <div className='col-12 text-center px-0'>
                  {deepLink ? (
                    windowWidth < WIDTH_MD &&
                      <button
                        type='submit'
                        title={t('continue')}
                        disabled={!accept || loading}
                        className='btn btn-orange--extra fs-14 fw-600 rounded-2 mx-auto text-white text-none'
                        onClick={() => {
                          window.location.href = deepLink
                        }}
                      >
                        {loading ? t('sending') : t('continue')}
                      </button>
                    ) : (
                      windowWidth < WIDTH_MD &&
                        <button
                          type='submit'
                          title={t('continue')}
                          disabled={!accept || loading}
                          className='btn btn-orange--extra fs-14 fw-600 rounded-2 mx-auto text-white text-none'
                        >
                          {loading ? t('sending') : t('continue')}
                        </button>
                    )
                  }
                  {windowWidth >= WIDTH_MD &&
                    <button
                      type='submit'
                      title={t('continue')}
                      disabled={!accept || loading}
                      className='btn btn-orange--extra fs-14 fw-600 rounded-2 mx-auto text-white text-none'
                    >
                      {loading ? t('sending') : t('continue')}
                    </button>
                  }
                </div>
              </div>
            </form>
            <div className='text-center pt-3'>
              <Link
                title={t('knowMore')}
                to='/pra-voce/conta-digital/pessoa-fisica/'
                className='fs-14 lh-17 fw-600 text-orange--extra pt-4 d-block'
                onClick={() => {
                  sendDatalayerEvent({
                    section: section,
                    section_name: sectionName,
                    element_name: t('knowMore'),
                    element_action: 'click button',
                    element_previous: t('isHaveAnAccount'),
                    redirect_url: window.location.href + '/pra-voce/conta-digital/pessoa-fisica/',
                  })
                }}
              > {t('knowMore')}
              </Link>
              <span className='fs-14 lh-17 text-grayscale--400'>
                {downloadAppCustomMessage ? '' : t('alreadyRegistered')}
                <button
                  title={t('downloadAppNow')}
                  className='text-orange--extra fw-600 bg-transparent border-0'
                  onClick={(evt: MouseEvent<HTMLButtonElement>) => {
                    asFilled(evt)
                    sendDatalayerEvent({
                      section: section,
                      section_name: sectionName,
                      element_name: t('downloadApp'),
                      element_action: 'click button',
                      element_previous: t('isHaveAnAccount'),
                      redirect_url: windowWidth < WIDTH_MD ? urlMobile : null,
                    })
                  }
                }
                >{downloadAppCustomMessage ? downloadAppCustomMessage : t('downloadApp')}
                </button>
              </span>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default translate('OpenAccountRightForm')(OpenAccountSectionForm)
