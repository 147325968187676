import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

const bgVidaFinanceiraMobile = 'https://central-imagens.bancointer.com.br/images-without-small-versions/super-app-dobra5-mobile/image.webp'
const bgVidaFinanceiraTabletDesktop = 'https://central-imagens.bancointer.com.br/images-without-small-versions/super-app-dobra5/image.webp'

export const SectionEscolhaInteligente = styled.section`
  min-height: 780px;
  padding: 40px 0 56px 0;
  background-image: url(${bgVidaFinanceiraMobile});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left -450px;

  @media ${device.tablet} {
    background-image: url(${bgVidaFinanceiraMobile});
    min-height: 591px;
    background-position: center center;
    padding-bottom: 40px;
  }
  @media ${device.desktopLG} {
    background-image: url(${bgVidaFinanceiraTabletDesktop});
    min-height: 591px;
  }
  @media ${device.desktopXL} {
    min-height: 807px;
  }
  @media ${device.desktopXXXL} {
    min-height: 1085px;
  }
`

export const Button = styled.section`
  @media ${device.tablet} {
    max-width: 287px;
  }
  @media ${device.desktopLG} {
    max-width: 457px;
  }
  @media ${device.desktopXL} {
    max-width: 454px;
  }
`
