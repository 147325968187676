import React, { useState } from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import OrangeIcon from 'src/components/UI/MarkdownIcon/InterCoIcons'

import { ItemsProps, dataProps, infoDataProps } from './types'

import * as S from './style'

const ToggleAccordeon = ({ infoData, section, sectionName }: infoDataProps) => {
  const data = infoData
  const [ sendDatalayerEvent ] = useDataLayer()

  const [ opened, setOpened ] = useState([ false ])

  function toggleAccordeon (index: number) {
    const newOpenedState = new Array(3).fill(false)
    newOpenedState[index] = true
    setOpened([ ...newOpenedState ])
    if (opened[index]) {
      setOpened([ false ])
    }
  }

  return (
    <div className='col-12 px-4'>
      {
        data.infos.map((item: dataProps, index: number) => (
          <S.Accordeon key={item.id} className={opened[index] ? 'active' : 'cursor-pointer'}>
            <S.AccordeonTitle onClick={() => {
              toggleAccordeon(index)
              sendDatalayerEvent({
                section: section,
                section_name: sectionName,
                element_action: 'click button',
                element_name: item.title,
              })
            }}
            >
              <h3
                title={item.title}
                className='fs-28 lh-34 text-new-orange-dark fw-700 mb-0'
                dangerouslySetInnerHTML={{ __html: item.title }}
              />
              <div className={`${opened[index] ? 'arrow-active' : ''} d-flex justify-content-center align-items-center`}>
                <OrangeIcon icon='chevron-down' color='#72370E' size='SM' />
              </div>
            </S.AccordeonTitle>
            <hr style={{ borderTop: '1px dashed #DEDFE4' }} className='w-100 mb-lg-5 mb-xl-3' />
            <S.AccordeonContent className={`col-12 pr-0 ${item.id === 0 ? 'd-block' : ''}`}>
              {
                item.items.map((values: ItemsProps) => (
                  <S.AccordeonContent key={values.icon} className='d-flex align-items-center justify-content-between mb-3'>
                    <S.InfoList className='d-flex justify-content-between justify-content-md-start'>
                      <li
                        className='fs-14 lh-17 text-grayscale--500 mb-0'
                        dangerouslySetInnerHTML={{ __html: values.description }}
                      />
                    </S.InfoList>
                    {values.icon &&
                      <OrangeIcon icon={values.icon} color='#EA7100' size='SM' />
                    }
                  </S.AccordeonContent>
                ))
              }
            </S.AccordeonContent>
          </S.Accordeon>
        ))
      }
    </div>
  )
}

export default ToggleAccordeon
