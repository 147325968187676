import React, { useState } from 'react'
import ToggleAccordeon from 'src/components/ToggleAccordeon'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

import dataProps from './../../assets/data/voce-nao-sabia.json'
import SectionComponent from 'src/components/SectionComponent'

import { ModalPropsSuperApp } from '../../types'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

const VoceNaoSabia = ({ setIsModal, setDataLayer }: ModalPropsSuperApp) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const data = dataProps

  const [ dataLayer ] = useState<IDataLayerParams>({
    section: 'dobra_05',
    section_name: 'Conta digital, investimentos, shopping e mais.',
    element_action: 'click button',
    element_name: 'Conhecer',
  })

  const Button = styled.section`
    @media ${device.tablet} {
      max-width: 246px;
    }
    @media ${device.desktopLG} {
      max-width: 376px;
    }
    @media ${device.desktopXL} {
      max-width: 454px;
    }
  `

  return (
    <SectionComponent
      id='o-primeiro-super-app'
      SectionStyles='bg-yellow--extra-light py-5'
      minHeight={{ sm: '690px', md: '765px', lg: '724px', xl: '796px', xxl: '1080px' }}
    >
      <div className='col-12 col-md-6 col-lg-5 text-center text-md-left px-md-0'>
        <h2 className='font-citrina text-primary--500 fs-40 lh-44 fs-md-48 lh-md-52 fs-lg-56 lh-lg-61 fs-xxl-80 lh-xxl-90'>
          Conta digital, investimentos, shopping e mais.
        </h2>
        <Button
          title='Conhecer'
          className='btn btn--lg text-white bg-primary--500 my-4 mb-md-0 text-none'
          onClick={() => {
            setIsModal && setIsModal(true)
            setDataLayer(dataLayer)
            sendDatalayerEvent(dataLayer)
          }}
        >
          Conhecer
        </Button>
      </div>
      <div className='col-12 col-md-6 mt-2 mt-md-0'>
        <ToggleAccordeon
          infoData={data}
          section='dobra_05'
          sectionName='Você não sabia que precisava, até ser Inter.'
        />
      </div>
    </SectionComponent>
  )
}

export default VoceNaoSabia
