import React, { useState } from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { ModalPropsSuperApp } from '../../types'
import { SectionVidaFinanceira } from './styles'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

const ParaUmavidaFinanceira = ({ setIsModal, setDataLayer }: ModalPropsSuperApp) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const [ dataLayer ] = useState<IDataLayerParams>({
    section: 'dobra_04',
    section_name: 'Para uma vida financeira mais inteligente.',
    element_action: 'click button',
    element_name: 'Saiba mais',
  })

  return (
    <SectionVidaFinanceira
      id='para-uma-vida-financeira-mais-inteligente'
      role='img'
      aria-label='Homem segurando celular e acessando o Super App financeiro Inter'
      className='d-flex align-items-end align-items-md-center'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-7 col-lg-8 mx-auto text-center'>
            <h2 className='font-citrina text-white fs-40 lh-44 fs-md-48 lh-md-52 fs-lg-56 lh-lg-61 fs-xxl-80 lh-xxl-90 text-center mt-md-5'>
              Para uma vida financeira mais inteligente.
            </h2>
            <button
              title='Saiba mais'
              className='btn btn--lg text-white bg-primary--500 mt-4 text-none'
              onClick={() => {
                setIsModal && setIsModal(true)
                setDataLayer(dataLayer)
                sendDatalayerEvent(dataLayer)
              }}
            >
              Saiba mais
            </button>
          </div>
        </div>
      </div>
    </SectionVidaFinanceira>
  )
}

export default ParaUmavidaFinanceira
