import styled from 'styled-components'
import { grayscale, white } from 'src/styles/colors'
import { breakpoints } from 'src/styles/breakpoints'

export const Container = styled.div`
  background-color: ${white};
  overflow-y: auto;
  border-radius: 16px;
  padding: 40px 30px;

  @media (min-width: ${breakpoints.sm}) {
    padding-left: 40px;
    padding-right: 40px;
  }

  @media (min-width: ${breakpoints.md}) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (min-width: ${breakpoints.lg}) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .form--default {
    label {
      color: ${grayscale[400]};
      margin-bottom: 4px;
    }

    .form-label-check {
      &::before {
        top: 34px;

        @media (min-width: ${breakpoints.md}) {
          top: 26px;
        }
        @media (min-width: ${breakpoints.xxll}) {
          top: 17px;
        }
      }
    }
    .form-input-check {
      &:checked ~ label {
      &::after {
        top: 37px;
          @media (min-width: ${breakpoints.md}) {
            top: 29px;
          }
          @media (min-width: ${breakpoints.xxll}) {
            top: 20px;
          }
        }
      }
    }
  }

  &.sent {
    @media (min-width: ${breakpoints.md}) {
      padding-left: 5px;
      padding-right: 5px;
    }
    @media (min-width: ${breakpoints.lg}) {
      padding-left: 20px;
      padding-right: 20px;
    }
    @media (min-width: ${breakpoints.xxxl}) {
      padding-left: 50px;
      padding-right: 50px;
    }
    @media (min-width: ${breakpoints.xxll}) {
      padding-left: 70px;
      padding-right: 70px;
    }
  }

  &.filled {
    @media (min-width: ${breakpoints.md}) {
      padding-left: 30px;
      padding-right: 30px;
    }
    @media (min-width: ${breakpoints.xl}) {
      min-height: 600px;
    }
  }
`
