import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      daPraFalarDeDinheiroDesktop: imageSharp(fluid: {originalName: { regex: "/da-pra-falar-de-dinheiro-desktop/" }}) {
        fluid(maxWidth: 859, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      daPraFalarDeDinheiroMobile: imageSharp(fluid: {originalName: { regex: "/da-pra-falar-de-dinheiro-mobile/" }}) {
        fluid(maxWidth: 458, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
