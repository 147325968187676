import React, { useState } from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

import { ModalPropsSuperApp } from '../../types'
import { SectionEscolhaInteligente, Button } from './styles'

const EscolhaInteligente = ({ setIsModal, setDataLayer }: ModalPropsSuperApp) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const [ dataLayer ] = useState<IDataLayerParams>({
    section: 'dobra_07',
    section_name: 'Escolha inteligente. Escolha Inter.',
    element_action: 'click button',
    element_name: 'Abra sua conta',
  })

  return (
    <SectionEscolhaInteligente
      id='para-uma-vida-financeira-mais-inteligente'
      role='img'
      aria-label='Mulher segurando celular e acessando o Super App financeiro Inter'
      className='d-flex align-items-end align-items-md-center'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12 mx-auto text-center'>
            <h2 className='font-citrina text-white fs-40 lh-44 fs-md-48 lh-md-52 fs-lg-56 lh-lg-61 fs-xxl-80 lh-xxl-90'>
              <span className='d-block'>Escolha inteligente.</span> Escolha Inter.
            </h2>
            <Button
              title='Abra sua conta'
              className='btn btn--lg text-white bg-primary--500 mt-4 text-none'
              onClick={() => {
                setIsModal && setIsModal(true)
                setDataLayer(dataLayer)
                sendDatalayerEvent(dataLayer)
              }}
            >
              Abra sua conta
            </Button>
          </div>
        </div>
      </div>
    </SectionEscolhaInteligente>
  )
}

export default EscolhaInteligente
